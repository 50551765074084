import { data } from "jquery";
import { http } from "../utils/http";

export const siteQuery = (data) =>
  http.post("/weilan/api/site/info/query", data);
export const siteAdd = (data) => http.post("/weilan/api/site/info/add", data);
export const siteDel = (data) =>
  http.post("/weilan/api/site/info/delete", data);
export const siteEdit = (data) =>
  http.post("/weilan/api/site/info/modify", data);

export const cmdRequest = (data) =>
  http.post("/weilan/api/site/info/cmdRequest", data);

export const getSiteDetail = (data) =>
  http.post("/weilan/api/site/info/getSiteDetail", data);
export const siteStatisticsInfo = (data) =>
  http.post("/weilan/api/home/page/siteStatisticsInfo", data);

export const oneSiteHomeInfo = (data) =>
  http.get("/weilan/api/home/page/oneSiteHomeInfo", data);

export const sitePowerStatistics = (data) =>
  http.post("/weilan/api/home/page/sitePowerStatistics", data);

export const oneStatisticsInfo = (data) =>
  http.post("/weilan/api/home/page/oneStatisticsInfo", data);

export const deviceModelQuery = (data) =>
  http.post("/weilan/api/site/config/findByPage", data);

export const deviceModelAdd = (data) =>
  http.post("/weilan/api/site/config/add", data);

export const deviceModelDelete = (data) =>
  http.get("/weilan/api/site/config/delete", data);
export const deviceModelEdit = (data) =>
  http.post("/weilan/api/site/config/edit", data);
